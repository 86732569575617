import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from 'environments/environment';
import { AppModule } from 'app/app.module';
import { hmrBootstrap } from './hmr';
import { CometChat } from '@cometchat-pro/chat';
import { COMETCHAT_CONSTANTS } from './CONSTS';

if (environment.production) {
    enableProdMode();
}

// const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule)

// if (environment.hmr) {
//     if (module['hot']) {
//         hmrBootstrap(module, bootstrap);
//     } else {
//         console.error('HMR is not enabled for webpack-dev-server!');
//         console.log('Are you using the --hmr flag for ng serve?');
//     }
// }
// else {
//     bootstrap().catch(err => console.log(err));
// }

const appSetting = new CometChat.AppSettingsBuilder().setRegion(COMETCHAT_CONSTANTS.REGION).subscribePresenceForAllUsers().build();
CometChat.init(COMETCHAT_CONSTANTS.APP_ID, appSetting).then(() => {


    platformBrowserDynamic().bootstrapModule(AppModule)
        .catch(err => console.error(err));
}, (error) => {
});