import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FuseAlertType } from '@fuse/components/alert';
import {
    ImageCroppedEvent,
    LoadedImage,
    base64ToFile,
} from 'ngx-image-cropper';

@Component({
    selector: 'app-image-crop',
    templateUrl: './image-crop.component.html',
})
export class ImageCropComponent implements OnInit {
    imageChangedEvent: any = '';
    croppedImage: any = '';
    showCropper = false;
    containWithinAspectRatio = false;
    transform = {};
    croppedImageFile;
    dataToTransfer: {
        file;
        croppedImage;
    };

    constructor(
        public matDialogRef: MatDialogRef<ImageCropComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {
        console.log(this.data);
        this.imageChangedEvent = this.data.imageChangedEvent;
    }

    close() {
        this.matDialogRef.close();
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
        console.log('cropped image', event);
        this.croppedImageFile = this.base64ToFile(
            event.base64,
            this.data.imageChangedEvent.target.files[0].name
        );

        // return fileToReturn;
        console.log(this.croppedImageFile);
    }

    base64ToFile(data, filename) {
        const arr = data.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    imageLoaded(event) {
        console.log(event);
        this.showCropper = true;
        // show cropper
    }
    cropperReady(sourceImageDimensions) {
        // cropper ready
        console.log('Cropper ready', sourceImageDimensions);
    }
    loadImageFailed() {
        // show message
        console.log('Load failed');
    }

    done(): void {
        console.log('done');
        // this.dataToTransfer.croppedImage = this.croppedImage;
        // this.dataToTransfer.file = this.croppedImageFile;
        this.matDialogRef.close(this.dataToTransfer ={croppedImage: this.croppedImage, file:this.croppedImageFile});
    }
}
