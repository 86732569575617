import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class TutorOnBoardGuard implements CanActivate {
    /**
     * Constructor
     */
    constructor(
        private _authService: AuthService,
        private _router: Router
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Can activate
     *
     * @param route
     * @param state
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
        return this._checkOnboarding(route.url);
    }

    /**
     * Can load
     *
     * @param route
     * @param segments
     */

    private _checkOnboarding(route): Observable<boolean> {
        // Check the authentication status
        return this._authService.checkOnboarding()
            .pipe(
                switchMap((onboarded) => {
                    // If the user is onboarded...
                    if (onboarded) {
                        // navigate to dashboard
                        this._router.navigateByUrl('tutor/dashboard');
                        return of(false)
                    }

                    return of(true)

                })
            );
    }
}
