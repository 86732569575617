<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full">

    <!-- header -->
    <div
        class="fixed flex flex-col flex-0 justify-center w-full h-14 sm:h-14 overflow-hidden z-49 shadow-lg"
    >
        <!-- Top bar -->
        <div
            class="header relative flex flex-auto justify-center w-full px-4 md:px-8"
        >
            <div
                class="flex items-center justify-between w-full max-w-360 h-14 sm:h-14"
            >
                <!-- Logo -->
                <ng-container>
                    <div class="flex items-center">
                        <!-- <h3>ONTUTE</h3> -->
                        <img
                            class="w-24 invert"
                            src="assets/images/logo/ontute-logo.svg"
                            alt="Logo image"
                        />
                    </div>
                </ng-container>
            </div>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

</div>
