<div class="flex my-4">
    <pdf-viewer
      [src]="src"
      [rotation]="0"
      [original-size]="false"
      [show-all]="true"
      [fit-to-page]="false"
      [zoom]="0.8"
      [zoom-scale]="'page-width'"
      [stick-to-page]="false"
      [render-text]="true"
      [external-link-target]="'blank'"
      [autoresize]="true"
      [show-borders]="false"
      style="width: 100%; height: 100vh;"
    ></pdf-viewer>
</div>
