/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: 'heroicons_outline:home',
        link: '/tutor/dashboard'
    },
    {
        id: 'order',
        title: 'Orders',
        type: 'basic',
        icon: 'heroicons_outline:clipboard-check',
        link: '/tutor/order'
    },
    {
        id: 'wallet',
        title: 'Wallet',
        type: 'basic',
        icon: 'heroicons_outline:briefcase',
        link: '/tutor/wallet'
    },
    {
        id: 'setting',
        title: 'Settings',
        type: 'basic',
        icon: 'heroicons_outline:cog',
        link: '/tutor/setting'
    },
    {
        id: 'inbox',
        title: 'Inbox',
        type: 'basic',
        icon: 'heroicons_outline:chat-alt',
        link: '/chat',
        badge: {
            title: '27',
            classes: 'px-2 bg-pink-600 text-white rounded-full'
        }
    },
    // {
    //     id   : 'student-dashboard',
    //     title: 'Student Dashboard',
    //     type : 'basic',
    //     icon : 'heroicons_outline:home',
    //     link : '/student/dashboard'
    // },
];

export const studentNavigation: FuseNavigationItem[] = [
    {
        id: 'student-dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: 'heroicons_outline:home',
        link: '/student/dashboard',
    },

    {
        id: 'student-setting',
        title: 'Settings',
        type: 'basic',
        icon: 'heroicons_outline:cog',
        link: '/student/setting',
    },
    // {
    //     id: 'wallet',
    //     title: 'Wallet',
    //     type: 'basic',
    //     icon: 'heroicons_outline:cog',
    //     link: '/student/wallet'
    // },
    {
        id: 'order',
        title: 'Orders',
        type: 'basic',
        icon: 'heroicons_outline:clipboard-check',
        link: '/student/order',
    },
    {
        id: 'inbox',
        title: 'Inbox',
        type: 'basic',
        icon: 'heroicons_outline:chat-alt',
        link: '/chat',
        badge: {
            title: '27',
            classes: 'px-2 bg-pink-600 text-white rounded-full'
        }
    },

];

export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
