import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ResetPasswordInput } from 'app/models/reset_password.type';
import { UserDetailsUpdate } from 'app/models/user_details_update.type';
import {
    InputEducation,
    InputLevelRate,
    InputTutorSubject,
    OnboardDetail,
} from 'app/tutor/onboarding/onboarding.type';
import { tap } from 'rxjs';
import { getLoggedInUser } from '../auth/auth.service.queries';
import {
    removeTutorSubject,
    getAllCities,
    getAllCountries,
    getAllEducationLevel,
    getAllLanguages,
    getAllSubject,
    getAParticularTutor,
    getBasicAccountDetails,
    getCitiesOfParticularCountry,
    getCommonDropDownData,
    getLevelsOfParticularSubject,
    getProgramsOfParticularEducation,
    getTutorSubjectDetails,
    onboardTutor,
    searchTutorByName,
    searchTutorBySubject,
    updateTutorSubject,
    updateUserDetail,
    addTutorSubject,
    removeTutorEducation,
    addTutorEducation,
    getTutorEducation,
    getTutorSubjectList,
    resetPassword,
    getMySchedule,
    updateMySchedule,
    getSlotsByDay,
    updateTutorTimezone,
    createBooking,
    getMyBookingsStudent,
    getMyBookingsTutor,
    updateBookingStatus,
    getTutorWallet,
    getStudentWallet,
    createReview,
    getReviews,
    otpMobileNumber,
    getTutorStats,
    connectToPayoneer,
    requestWithdraw,
    sendOtp,
    otpVerificationOnly,
    getTutorStars,
    sendOtpForOnboarding,
    getTutorSchedule,
    forgotPassword,
    setPassword,
    getAllSubjectsByGroup,
    getAllTutors,
    updateBookingStatusAfterPayment,
    getAvailableAmountInTutorWallet,
    markBookingAsCancelledStudent,
    addOrUpdateMessageReminder,
    resetUnreadMessageCount,
    updateIdentityDocumentTutor,
    getPayeeStatus,
    addPaymentDetails,
    updatePaymentDetails,
    createMultipleBooking,
    getMyBookingsStudentNew,
    updateBookingStatusAfterPaymentByBatchNumber,
    getBookingsByBatchNumber,
    saveTransaction,
    contactUs,
} from './ontute.service.queries';
import { BankDetails } from 'app/models/tutor_bank_detail.type';
import { AlertBoxComponent } from 'app/shared/alert-box/alert-box.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class OntuteService {
    /**
     * Constructor
     */
    constructor(private apollo: Apollo, private _matDialog: MatDialog) { }

    public onboardTutor(onboardDetail: OnboardDetail) {
        return this.apollo.mutate({
            mutation: onboardTutor,
            variables: {
                tutorDetailInput: onboardDetail,
            },
        });
    }

    public getBookingsByBatchNumber(batch_number: string) {
        return this.apollo.query({
            query: getBookingsByBatchNumber,
            variables: { batch_number }
        })
    }

    public updateBookingStatusAfterPaymentByBatchNumber(batch_number: string) {
        return this.apollo.mutate({
            mutation: updateBookingStatusAfterPaymentByBatchNumber,
            variables: { batch_number }
        })
    }

    public getAllSubjects() {
        return this.apollo.query({
            query: getAllSubject,
            fetchPolicy: 'network-only',
        });
    }

    public getAllSubjectsByGroup() {
        return this.apollo.query({
            query: getAllSubjectsByGroup,
            fetchPolicy: 'network-only',
        });
    }

    public getAvailableAmountInTutorWallet() {
        return this.apollo.query({
            query: getAvailableAmountInTutorWallet,
            fetchPolicy: 'network-only',
        });
    }

    public contactUs(variables: any) {
        return this.apollo.mutate({
            mutation: contactUs,
            variables
        })
    }

    public getLevelsOfParticularSubject(subjectId: string) {
        return this.apollo.query({
            query: getLevelsOfParticularSubject,
            variables: {
                subjectId: subjectId,
            },
            fetchPolicy: 'network-only',
        });
    }

    public getAllEducationLevel() {
        return this.apollo.query({
            query: getAllEducationLevel,
            fetchPolicy: 'network-only',
        });
    }

    public getProgramsOfParticularEducation(educationId: string) {
        return this.apollo.query({
            query: getProgramsOfParticularEducation,
            variables: {
                educationId: educationId,
            },
            fetchPolicy: 'network-only',
        });
    }

    public getBasicAccountDetails() {
        return this.apollo.query({
            query: getBasicAccountDetails,
            fetchPolicy: 'network-only',
        });
    }

    public getAllCountries() {
        return this.apollo.query({
            query: getAllCountries,
            fetchPolicy: 'network-only',
        });
    }

    public getAllCities() {
        return this.apollo.query({
            query: getAllCities,
            fetchPolicy: 'network-only',
        });
    }

    public getCitiesOfParticularCountry(countryId: string) {
        return this.apollo.query({
            query: getCitiesOfParticularCountry,
            variables: {
                countryId: countryId,
            },
            fetchPolicy: 'network-only',
        });
    }

    public getAllLanguages() {
        return this.apollo.query({
            query: getAllLanguages,
            fetchPolicy: 'network-only',
        });
    }

    public getCommonDropDownData() {
        return this.apollo.query({
            query: getCommonDropDownData,
            fetchPolicy: 'network-only',
        });
    }
    public markBookingAsCancelledStudent(bookingId: string, reason: string) {
        return this.apollo.mutate({
            mutation: markBookingAsCancelledStudent,
            variables: {
                bookingId: bookingId,
                reasonForCancellation: reason
            },
        });
    }

    public updateUserDetail(userDetailInput: UserDetailsUpdate) {
        return this.apollo.mutate({
            mutation: updateUserDetail,
            variables: {
                userDetailInput: userDetailInput,
            },
        });
    }

    public updateIdentityDocumentTutor(documentNumber: string, cnicImage1: string, cnicImage2: string) {
        return this.apollo.mutate({
            mutation: updateIdentityDocumentTutor,
            variables: {
                documentNumber: documentNumber,
                cnicImage1: cnicImage1,
                cnicImage2: cnicImage2
            },
        });
    }


    public getAllTutors(
        status: string,
        searchTerm: string,
        limit: number,
        pageIndex: number,
        order?: string,
        orderBy?: string,
        studyType?: string,
        minPrice?: any,
        maxPrice?: any,
        subjectName?: string,
        levelName?: string
    ): any {
        const options = {
            order,
            orderBy,
            studyType,
            minPrice: parseFloat(minPrice),
            maxPrice: parseFloat(maxPrice)
        };

        if (status) { options['status'] = status; };
        if (searchTerm) { options['searchTerm'] = searchTerm; };
        // if(criteria)
        //     {options['criteria'] = criteria;};
        if (limit) { options['limit'] = limit; };
        if (pageIndex) { options['pageIndex'] = pageIndex; };
        if (subjectName) { options['subjectName'] = subjectName; };
        if (levelName) { options['levelName'] = levelName; };

        return this.apollo.query({
            query: getAllTutors,
            variables: options,
            fetchPolicy: 'network-only',
        });
    }

    public getAParticularTutor(tutorId: string): any {
        return this.apollo.query({
            query: getAParticularTutor,
            variables: {
                tutorId: tutorId,
            },
            fetchPolicy: 'network-only',
        });
    }

    public getReviews(tutorId: string) {
        return this.apollo.query({
            query: getReviews,
            variables: {
                tutorId: tutorId,
            },
            fetchPolicy: 'network-only',
        });
    }

    public getTutorStars(tutorId: string) {
        return this.apollo.query({
            query: getTutorStars,
            variables: {
                tutorId: tutorId,
            },
            fetchPolicy: 'network-only',
        });
    }


    public searchTutorBySubject(subjectName: string) {
        return this.apollo.query({
            query: searchTutorBySubject,
            variables: {
                subjectName: subjectName,
            },
            fetchPolicy: 'network-only',
        });
    }

    public searchTutorByName(tutorName: string) {
        return this.apollo.query({
            query: searchTutorByName,
            variables: {
                tutorName: tutorName,
            },
            fetchPolicy: 'network-only',
        });
    }

    public getTutorSubjectDetails(tutorSubjectId: string) {
        return this.apollo.query({
            query: getTutorSubjectDetails,
            variables: {
                tutorSubjectId: tutorSubjectId,
            },
            fetchPolicy: 'network-only',
        });
    }

    public updateTutorSubject(
        tutorSubjectId: string,
        subjectLevelRate: InputLevelRate[]
    ) {
        return this.apollo.mutate({
            mutation: updateTutorSubject,
            variables: {
                tutorSubjectId: tutorSubjectId,
                subjectLevelRate: subjectLevelRate,
            },
        });
    }

    public addPaymentDetails(
        paymentMethod: string,
        payoneerEmail?: string,
        inputBankDetails?: BankDetails
    ) {
        const parameters = {
            paymentMethod: paymentMethod,
        };
        if (payoneerEmail) { parameters['payoneerEmail'] = payoneerEmail; }
        // if(inputBankDetails) { 
        //     inputBankDetails = Object.fromEntries(Object.entries(inputBankDetails).filter(([_, v]) => v !== null && v!== ''));
        //     parameters['inputBankDetails'] = inputBankDetails;
        // }
        parameters['inputBankDetails'] = inputBankDetails;

        return this.apollo.mutate({
            mutation: addPaymentDetails,
            variables: parameters,
            fetchPolicy: 'no-cache',
        });

    }

    public updatePaymentDetails(
        paymentMethod: string,
        payoneerEmail: string,
        updateBankDetails: BankDetails
    ) {
        const parameters = {
            paymentMethod: paymentMethod,
        };
        if (payoneerEmail) { parameters['payoneerEmail'] = payoneerEmail; }
        if (updateBankDetails) { parameters['updateBankDetails'] = updateBankDetails; }

        return this.apollo.mutate({
            mutation: updatePaymentDetails,
            variables: parameters,
            fetchPolicy: 'no-cache',
        });

    }

    public removeTutorSubject(tutorSubjectId: string) {
        return this.apollo.mutate({
            mutation: removeTutorSubject,
            variables: {
                tutorSubjectId: tutorSubjectId,
            },
        });
    }

    public addTutorSubject(tutorSubject: InputTutorSubject) {
        return this.apollo.mutate({
            mutation: addTutorSubject,
            variables: {
                tutorSubject: tutorSubject,
            },
        });
    }

    public removeTutorEducation(tutorEducationId: string) {
        return this.apollo.mutate({
            mutation: removeTutorEducation,
            variables: {
                tutorEducationId: tutorEducationId,
            },
        });
    }

    public addTutorEducation(tutorEducation: InputEducation) {
        return this.apollo.mutate({
            mutation: addTutorEducation,
            variables: {
                tutorEducation: tutorEducation,
            },
        });
    }

    public getTutorEducation() {
        return this.apollo.query({
            query: getTutorEducation,
            fetchPolicy: 'network-only',
        });
    }

    public getTutorSubjectList() {
        return this.apollo.query({
            query: getTutorSubjectList,
            fetchPolicy: 'network-only',
        });
    }

    public otpMobileNumber(mobileNumber: string) {
        return this.apollo.mutate({
            mutation: otpMobileNumber,
            variables: {
                mobileNumber: mobileNumber,
            },
        });
    }

    public sendOtp() {
        return this.apollo.mutate({
            mutation: sendOtp,
        });
    }


    public sendOtpForOnboarding(contactNo: string) {
        return this.apollo.mutate({
            mutation: sendOtpForOnboarding,
            variables: {
                contactNo: contactNo
            }
        });
    }

    public resetPassword(resetPasswordInput: ResetPasswordInput) {
        return this.apollo.mutate({
            mutation: resetPassword,
            variables: {
                resetPasswordInput: resetPasswordInput,
            },
        });
    }

    public setPassword(token: string, password: string) {
        return this.apollo.mutate({
            mutation: setPassword,
            variables: {
                token: token,
                password: password
            },
        });
    }



    public getMySchedule() {
        return this.apollo.query({
            query: getMySchedule,
            fetchPolicy: 'network-only',
        });
    }

    public getTutorSchedule(id: string) {
        return this.apollo.query({
            query: getTutorSchedule,
            variables: {
                tutorId: id
            },
            fetchPolicy: 'network-only',
        });
    }

    public forgotPassword(email: string) {
        return this.apollo.mutate({
            mutation: forgotPassword,
            variables: {
                email: email
            },
            fetchPolicy: 'network-only',
        });
    }

    public getSlotsByDay(params: {
        tutorId: string;
        timestampToExtractDay: number;
    }) {
        return this.apollo.query({
            query: getSlotsByDay,
            variables: {
                tutorId: params.tutorId,
                day: params.timestampToExtractDay,
            },
            fetchPolicy: 'network-only',
        });
    }

    public updateMySchedule(updateMyScheduleVar: any) {
        return this.apollo.mutate({
            mutation: updateMySchedule,
            variables: {
                updateMyScheduleVar: updateMyScheduleVar,
            },
        });
    }

    public createBooking(booking: any) {
        return this.apollo.mutate({
            mutation: createBooking,
            variables: {
                booking: booking,
            },
        });
    }

    public updateBookingStatusAfterPayment(bookingId: string) {
        return this.apollo.mutate({
            mutation: updateBookingStatusAfterPayment,
            variables: {
                bookingId: bookingId,
            },
        });
    }

    public createReview(review) {
        return this.apollo.mutate({
            mutation: createReview,
            variables: {
                review: review
            },
        });
    }

    public createMultipleBooking(variables: any) {
        return this.apollo.mutate({
            mutation: createMultipleBooking,
            variables
        })
    }

    public getLoggedInUser() {
        return this.apollo.query({
            query: getLoggedInUser,
            variables: {
            },
            fetchPolicy: 'network-only',
        });
    }

    public getMyBookingsStudent(option) {
        return this.apollo.query({
            query: getMyBookingsStudent,
            variables: {
                status: option.status,
                date: option.date,
                tutor: option.tutor,
                subject: option.subject,
                limit: option.limit,
                pageIndex: option.pageIndex
            },
            fetchPolicy: 'network-only',
        });
    }

    openAlertBox(title: string, type: string = 'success', isShared?: boolean) {
        this._matDialog.open(AlertBoxComponent, {
            data: { title, type, isShared: isShared ?? false }
        })
    }

    public getMyBookingsStudentNew(option: any) {
        return this.apollo.query({
            query: getMyBookingsStudentNew,
            variables: {
                status: option.status,
                date: option.date,
                tutor: option.tutor,
                subject: option.subject,
                limit: option.limit,
                pageIndex: option.pageIndex
            },
        })
    }

    public getMyBookingsTutor(option) {
        return this.apollo.query({
            query: getMyBookingsTutor,
            variables: {
                status: option.status,
                date: option.date,
                tutor: option.tutor,
                subject: option.subject,
                limit: option.limit,
                pageIndex: option.pageIndex
            },
            fetchPolicy: 'network-only',
        });
    }

    public getTutorStats() {
        return this.apollo.query({
            query: getTutorStats,
            fetchPolicy: 'network-only',
        });
    }

    public getTutorWallet(option) {
        return this.apollo.query({
            query: getTutorWallet,
            variables: {
                // status: option.status,
                // date: option.date,
                // tutor: option.tutor,
                // subject: option.subject
                limit: option.limit,
                pageIndex: option.pageIndex
            },
            fetchPolicy: 'network-only',
        });
    }


    public getStudentWallet() {
        return this.apollo.query({
            query: getStudentWallet,
            // variables: {
            //     status: option.status,
            //     date: option.date,
            //     tutor: option.tutor,
            //     subject: option.subject
            // },
            fetchPolicy: 'network-only',
        });
    }

    public updateTutorTimezone(timezoneParams: any) {
        return this.apollo.mutate({
            mutation: updateTutorTimezone,
            variables: {
                timezone: timezoneParams,
            },
            fetchPolicy: 'network-only',
        });
    }

    public updateBookingStatus(bookingId, status) {
        return this.apollo.mutate({
            mutation: updateBookingStatus,
            variables: {
                bookingId: bookingId,
                status: status
            },
            fetchPolicy: 'network-only',
        });
    }

    public saveTransaction(saveTransactionInput: any) {
        return this.apollo.mutate({
            mutation: saveTransaction,
            variables: {
                saveTransactionInput,
            },
        })
    }

    public connectToPayoneer() {
        return this.apollo.query({
            query: connectToPayoneer,
            // variables: {
            //     status: option.status,
            //     date: option.date,
            //     tutor: option.tutor,
            //     subject: option.subject
            // },
            fetchPolicy: 'network-only',
        });
    }

    public requestWithdraw(amount) {
        return this.apollo.mutate({
            mutation: requestWithdraw,
            variables: {
                requestWithdraw: {
                    amount: amount.toString()
                }
            },
        });
    }

    public sendOtpFun(otpValue) {
        return this.apollo.mutate({
            mutation: sendOtp,
            variables: {
                otp: otpValue
            },
        });
    }
    public otpVerification(otpValue) {
        return this.apollo.mutate({
            mutation: otpVerificationOnly,
            variables: {
                otp: otpValue
            },
        });
    }
    public addOrUpdateMessageReminder(
        conversationId,
        senderId,
        receiverId
    ) {
        return this.apollo.mutate({
            mutation: addOrUpdateMessageReminder,
            variables: {
                conversationId: conversationId,
                senderId: senderId,
                receiverId: receiverId
            },
        });
    }
    public resetUnreadMessageCount(
        conversationId,
        receiverId
    ) {
        return this.apollo.mutate({
            mutation: resetUnreadMessageCount,
            variables: {
                conversationId: conversationId,
                receiverId: receiverId
            },
        });
    }

    public getPayeeStatus() {
        return this.apollo.query({
            query: getPayeeStatus,
            fetchPolicy: 'network-only',
            errorPolicy: 'all'
        });
    }


}
