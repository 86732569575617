import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class TutorRoleGuard implements CanActivate {
    /**
     * Constructor
     */
    constructor(
        private _authService: AuthService,
        private _router: Router
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Can activate
     *
     * @param route
     * @param state
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
        return this._checkRoleing(route.url);
    }

    /**
     * Can load
     *
     * @param route
     * @param segments
     */

    private _checkRoleing(route): Observable<boolean> {
        // Check the authentication status
        return this._authService.check()
            .pipe(
                switchMap((onboarded) => {
                    // navigate to dashboard
                    let token = this._authService.accessToken,
                        base64Url = token.split('.')[1],
                        decodedValue = JSON.parse(window.atob(base64Url))

                    if (token && decodedValue && decodedValue['role_id'] === 1) {
                        // Redirect to the tutor
                        return of(true)
                    } else if (token && decodedValue && decodedValue['role_id'] === 2) {
                        // Redirect to the student
                        return of(false)
                    } else {
                        this._authService.signOut()
                    }
                })
            );
    }
}
