import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import { TutorOnBoardGuard } from './core/auth/guards/tutorOnboard.guard';
import { StudentOnBoardGuard } from './core/auth/guards/studentOnboardGuard.guard';
import { TutorRoleGuard } from './core/auth/guards/tutorRole.guard';
import { StudentRoleGuard } from './core/auth/guards/studentRole.guard';
import { EmailConfirmationGuard } from './core/auth/guards/emailConfirmationGuard';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    // Redirect empty path to '/example'
    { path: '', pathMatch: 'full', redirectTo: '/public/student-home' },

    // Redirect signed in user to the '/example'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    // { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'sign-up' },


    // Email Confirmation
    {
        path: '',
        canActivate: [EmailConfirmationGuard],
        canActivateChild: [EmailConfirmationGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'email-confirmation',
                loadChildren: () =>
                    import(
                        'app/modules/auth/confirmation-required/confirmation-required.module'
                    ).then((m) => m.AuthConfirmationRequiredModule),
            },
        ],
    },



    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            // {
            //     path: 'confirmation-required',
            //     loadChildren: () =>
            //         import(
            //             'app/modules/auth/confirmation-required/confirmation-required.module'
            //         ).then((m) => m.AuthConfirmationRequiredModule),
            // },
            {
                path: 'forgot-password',
                loadChildren: () =>
                    import(
                        'app/modules/auth/forgot-password/forgot-password.module'
                    ).then((m) => m.AuthForgotPasswordModule),
            },
            {
                path: 'reset-password',
                loadChildren: () =>
                    import(
                        'app/modules/auth/reset-password/reset-password.module'
                    ).then((m) => m.AuthResetPasswordModule),
            },
            {
                path: 'sign-in',
                loadChildren: () =>
                    import('app/modules/auth/sign-in/sign-in.module').then(
                        (m) => m.AuthSignInModule
                    ),
            },
            {
                path: 'otp-verify',
                loadChildren: () =>
                    import('app/modules/auth/otp-verification/otp-verification.module').then(
                        (m) => m.OtpVerificationModule
                    ),
            },
            {
                path: 'sign-up',
                loadChildren: () =>
                    import('app/modules/auth/sign-up/sign-up.module').then(
                        (m) => m.AuthSignUpModule
                    ),
            },
        ],
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'sign-out',
                loadChildren: () =>
                    import('app/modules/auth/sign-out/sign-out.module').then(
                        (m) => m.AuthSignOutModule
                    ),
            },
            {
                path: 'unlock-session',
                loadChildren: () =>
                    import(
                        'app/modules/auth/unlock-session/unlock-session.module'
                    ).then((m) => m.AuthUnlockSessionModule),
            },
        ],
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'home',
                loadChildren: () =>
                    import('app/modules/landing/home/home.module').then(
                        (m) => m.LandingHomeModule
                    ),
            },
            {
                path: 'confirmation',
                loadChildren: () =>
                    import(
                        'app/modules/landing/confirmation/confirmation.module'
                    ).then((m) => m.ConfirmationModule),
            },
        ],
    },

    // Tutor routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {
                path: 'tutor',
                canActivate: [TutorRoleGuard],
                children: [
                    {
                        path: 'dashboard',
                        loadChildren: () =>
                            import('app/tutor/dashboard/dashboard.module').then(
                                (m) => m.DashboardModule
                            ),
                    },
                ],
            },
            {
                path: 'tutor/setting',
                canActivate: [TutorRoleGuard],
                loadChildren: () =>
                    import('app/tutor/setting/setting.module').then(
                        (m) => m.SettingModule
                    ),
            },
            {
                path: 'tutor/order',
                canActivate: [TutorRoleGuard],
                loadChildren: () =>
                    import('app/tutor/order/order.module').then(
                        (m) => m.OrderModule
                    ),
            },
            {
                path: 'tutor/wallet',
                canActivate: [TutorRoleGuard],
                loadChildren: () =>
                    import('app/tutor/wallet/wallet.module').then(
                        (m) => m.WalletModule
                    ),
            },
            {
                path: 'chat',
                loadChildren: () =>
                    import('app/modules/admin/apps/chat/chat.module').then(
                        (m) => m.ChatModule
                    ),
            },
            {
                path: 'student/dashboard',
                canActivate: [StudentRoleGuard],
                loadChildren: () =>
                    import('app/student/dashboard/dashboard.module').then(
                        (m) => m.DashboardModule
                    ),
            },
            {
                path: 'student/setting',
                canActivate: [StudentRoleGuard],
                loadChildren: () =>
                    import('app/student/setting/setting.module').then(
                        (m) => m.SettingModule
                    ),
            },
            {
                path: 'student/tutor/:id/booking',
                canActivate: [StudentRoleGuard],
                loadChildren: () =>
                    import(
                        'app/student/particular-tutor-booking/particular-tutor-booking.module'
                    ).then((m) => m.ParticularTutorBookingModule),
            },
            {
                path: 'student/order',
                canActivate: [StudentRoleGuard],
                loadChildren: () =>
                    import('app/student/studentOrder/studentOrder.module').then(
                        (m) => m.StudentOrderModule
                    ),
            },
            {
                path: 'student/tutor/:id',
                canActivate: [StudentRoleGuard],
                loadChildren: () =>
                    import(
                        'app/student/particular-tutor-detail/particular-tutor-detail.module'
                    ).then((m) => m.PerticularTutorDetailModule),
            },
        ],
    },

    // Onboarding routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        // resolve: {
        //     initialData: InitialDataResolver,
        // },
        children: [
            {
                path: 'tutor/onboarding',
                canActivate: [TutorOnBoardGuard],
                loadChildren: () =>
                    import('app/tutor/onboarding/onboarding.module').then(
                        (m) => m.OnboardingModule
                    ),
            },
        ],
    },

    // students routes
    {
        path: '',
        // canActivate: [AuthGuard],
        // canActivateChild: [OnBoardGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        // resolve: {
        //     initialData: InitialDataResolver,
        // },
        children: [
            {
                path: 'student/onboarding',
                canActivate: [AuthGuard, StudentOnBoardGuard],
                loadChildren: () =>
                    import('app/student/onboarding/onboarding.module').then(
                        (m) => m.OnboardingModule
                    ),
            },
        ],
    },

    {
        path: 'terms_and_conditions',
        data: {
            layout: 'empty',
        },
        component: TermsConditionsComponent

    },

    {
        path: 'privacy_policy',
        data: {
            layout: 'empty',
        },
        component: PrivacyPolicyComponent

    },

    //public routes

    {
        path: 'public',
        loadChildren: () => import('./modules/public/public.module').then(m => m.PublicModule)
    }
];
