import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-alert-box',
  templateUrl: './alert-box.component.html',
  styleUrls: ['./alert-box.component.scss']
})
export class AlertBoxComponent implements OnInit {

  errorIcon: string = 'assets/images/logo/error icon.svg';
  warningIcon: string = 'assets/images/logo/warning icon.svg';
  successIcon: string = 'assets/images/logo/success icon.svg';
  counter: number = 0;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<AlertBoxComponent>) { }

  closeDialog() {
    this.dialogRef.close();
  }

  startTimeOut() {
    const timeout = setInterval(() => {
      this.counter += 1;
      if (this.counter == 3) {
        clearInterval(timeout);
        this.closeDialog();
      }
    }, 1000);
  }

  ngOnInit(): void {
    this.startTimeOut()
  }

}
