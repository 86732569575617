// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    hmr: false,
    // graphqlUrl: 'http://18.211.49.128:3000/graphql',
    graphqlUrl: 'https://ontute.builtinsoft.tech/graphql',
    // graphqlUrl: 'http://52.54.219.251:3000/graphql',
    // graphqlUrl: 'http://localhost:3000/graphql',
    recaptcha_site_key: '6LdevIopAAAAAG96pvWOGeSDUr1GBl6cfsyEkFi7',
    recaptcha_secret_key: '6LdevIopAAAAAHAUfdYUwd6ILHFwqDkOT5kpLY4a'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
