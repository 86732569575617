import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-terms-conditions',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './terms-conditions.component.html',
    styleUrls: ["./terms-conditions.component.scss"],
})
export class TermsConditionsComponent implements OnInit {
    src = './assets/files/Terms of Service.pdf';

    constructor() { }

    ngOnInit(): void {
    }

}
