import { createReducer, on } from "@ngrx/store"
import { dashboardActions } from "./dashboard.actions"

const initialState: any = {
    searchTerm: '',
    currentSubject: '',
    currentLevel: '',
    minimumPrice: 0,
    maximumPrice: 100,
    currentOrder: 'DESC',
    currentOrderBy: 'ratting',
    currentStudyType: 'both'
}

export const dashboardReducer = createReducer(
    initialState,
    on(dashboardActions.search, (state: any, { name, value }) => {
        return {
            ...state,
            [name]: value
        }
    })
);