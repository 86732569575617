import gql from 'graphql-tag';

/**
 * Queries
 */

export const getTutorSubjectDetails = gql`
    query getTutorSubjectDetails($tutorSubjectId: String!) {
        getTutorSubjectDetails(tutorSubjectId: $tutorSubjectId) {
            id
            subject {
                id
                name
            }
            tutorSubjectsLevel {
                level {
                    id
                    name
                }
                rate
            }
        }
    }
`;

export const contactUs = gql`
    mutation contactUs($name: String!, $description: String!, $from: String!){
        contactUs(name: $name, description: $description, from: $from)
    }
`;

export const getTutorEducation = gql`
    query getTutorEducationList {
        getTutorEducationList {
            id
            educationLevel {
                id
                name
            }
            degreeProgram {
                id
                name
            }
            fileLink
        }
    }
`;

export const getTutorSubjectList = gql`
    query getTutorSubjectList {
        getTutorSubjectList {
            id
            subject {
                id
                name
            }
            tutorSubjectsLevel {
                level {
                    id
                    name
                }
                rate
            }
        }
    }
`;

export const getAllTutors = gql`
query getAllTutors(
    $status: String
    $searchTerm: String
    $limit: Float
    $pageIndex: Float
    $orderBy: String
    $order: String
    $minPrice: Float
    $maxPrice: Float
    $studyType: String
    $subjectName: String
    $levelName: String
) {
    getAllTutors(
        status: $status, 
        searchTerm: $searchTerm
        limit: $limit
        pageIndex: $pageIndex
        orderBy: $orderBy
        order: $order
        minPrice: $minPrice
        maxPrice: $maxPrice
        studyType: $studyType
        subjectName: $subjectName
        levelName: $levelName
    ) {
        users {
            id
            firstName
            lastName
            description
            avatar
            onlineCoaching
            inPerson
            isEmailVerified
            overallRating
            numberOfReviews
            tutorSubjects {
                id
                subject {
                    id
                    name
                }
                tutorSubjectsLevel {
                    level {
                        name
                    }
                    rate
                }
            }

        }
        count 
    }
}
    
`;

export const searchTutorBySubject = gql`
    query searchTutorBySubject($subjectName: String!) {
        searchTutorBySubject(subjectName: $subjectName) {
            id
            firstName
            lastName
            avatar
            description
            onlineCoaching
            inPerson
            overallRating
            numberOfReviews
            tutorSubjects {
                id
                subject {
                    id
                    name
                }
                tutorSubjectsLevel {
                    level {
                        name
                    }
                    rate
                }
            }
        }
    }
`;

export const searchTutorByName = gql`
    query searchTutorByName($tutorName: String!) {
        searchTutorByName(tutorName: $tutorName) {
            id
            firstName
            lastName
            avatar
            description
            onlineCoaching
            inPerson
            overallRating
            numberOfReviews
            tutorSubjects {
                id
                subject {
                    id
                    name
                }
                tutorSubjectsLevel {
                    level {
                        name
                    }
                    rate
                }
            }
        }
    }
`;

export const getAParticularTutor = gql`
    query getAParticularTutor($tutorId: String!) {
        getAParticularTutor(tutorId: $tutorId) {
            id
            firstName
            lastName
            description
            onlineCoaching
            inPerson
            isEmailVerified
            overallRating
            numberOfReviews
            avatar
            language {
                id
                name
            }
            tutorSubjects {
                id
                subject {
                    id
                    name
                }
                tutorSubjectsLevel {
                    level {
                        id
                        name
                    }
                    rate
                }
            }
            tutorEducations {
                educationLevel {
                    name
                }
                degreeProgram {
                    name
                }
                fileLink
            }
        }
    }
`;

export const getAllSubject = gql`
    query getAllSubject {
        getAllSubject {
            id
            name
            levels {
                id
                name
            }
            subjectGroup {
                id
                name
            }
        }
    }
`;

export const getAllSubjectsByGroup = gql`
    query getAllSubjectsByGroup {
        getAllSubjectsByGroup {
            subjectGroup {
                id
                subjectGroupName
            }
            subjects {
            id
            subjectName
            }
        }
    }
`;

export const getLevelsOfParticularSubject = gql`
    query getLevelsOfParticularSubject($subjectId: String!) {
        getLevelsOfParticularSubject(subjectId: $subjectId) {
            id
            name
        }
    }
`;

export const getAllEducationLevel = gql`
    query getAllEducationLevel {
        getAllEducationLevel {
            id
            name
        }
    }
`;

export const getProgramsOfParticularEducation = gql`
    query getProgramsOfParticularEducation($educationId: String!) {
        getProgramsOfParticularEducation(educationId: $educationId) {
            id
            name
        }
    }
`;

export const getBasicAccountDetails = gql`
    query getBasicAccountDetails {
        getBasicAccountDetails {
            id
            firstName
            lastName
            description
            email
            contactNo
            gender
            city {
                id
                name
            }
            country {
                id
                name
            }
            language {
                id
                name
            }
            tutorSubjects {
                id
                subject {
                    id
                    name
                }
                tutorSubjectsLevel {
                    level {
                        name
                    }
                    rate
                }
            }
            tutorEducations {
                educationLevel {
                    name
                }
                degreeProgram {
                    name
                }
            }
            userRole {
                id
                name
            }
            inPerson
            isEmailVerified
            onlineCoaching
            isVerified
            overallRating
            numberOfReviews
        }
    }
`;

export const getAllCountries = gql`
    query getAllCountries {
        getAllCountries {
            id
            name
            code
        }
    }
`;

export const getAllCities = gql`
    query getAllCities {
        getAllCities {
            id
            name
        }
    }
`;

export const getCitiesOfParticularCountry = gql`
    query getCitiesOfParticularCountry($countryId: String!) {
        getCitiesOfParticularCountry(countryId: $countryId) {
            id
            name
        }
    }
`;

export const getAllLanguages = gql`
    query getAllLanguages {
        getAllLanguages {
            id
            name
        }
    }
`;

export const getCommonDropDownData = gql`
    query getCommonDropDownData {
        getAllLanguages {
            id
            name
        }

        getAllCountries {
            id
            name
        }
    }
`;

export const markBookingAsCancelledStudent = gql`
    mutation markBookingAsCancelledStudent($bookingId: String!, $reasonForCancellation:String!){
        markBookingAsCancelledStudent(bookingId:$bookingId, reasonForCancellation: $reasonForCancellation)
    }
`;

export const updateIdentityDocumentTutor = gql`
    mutation updateIdentityDocumentTutor($documentNumber: String!, $cnicImage1 : String!, $cnicImage2 : String!){
        updateIdentityDocumentTutor(
            documentNumber : $documentNumber, 
            cnicImage1 : $cnicImage1, 
            cnicImage2 :$cnicImage2){
            id
            firstName
            lastName
            description
            email
            contactNo
            avatar
            document_id
            gender
            city {
                id
                name
            }
            country {
                id
                name
            }
            language {
                id
                name
            }
            userRole {
                id
            }
            inPerson
            onlineCoaching
            cnic_image_1
            cnic_image_2
        }
    }
`;

export const updateUserDetail = gql`
    mutation updateUserDetail($userDetailInput: UserDetailsUpdateDTO!) {
        updateUserDetail(userDetailInput: $userDetailInput) {
            id
            firstName
            lastName
            description
            email
            document_id
            contactNo
            avatar
            gender
            cnic_image_1
            cnic_image_2
            city {
                id
                name
            }
            country {
                id
                name
            }
            language {
                id
                name
            }
            userRole {
                id
            }
            inPerson
            onlineCoaching
        }
    }
`;

/**
 * Mutations
 */

export const onboardTutor = gql`
    mutation onboardTutor($tutorDetailInput: OnboardTutorDTO!) {
        onboardTutor(tutorDetailInput: $tutorDetailInput) {
            id
            firstName
            lastName
            description
            email
            contactNo
            gender
            avatar
            cnic_image_1
            cnic_image_2
            label
            utc
            utcOffset
            city {
                id
                name
            }
            country {
                id
                name
            }
            language {
                id
                name
            }
            inPerson
            onlineCoaching
            userRole {
                id
                name
            }
            tutorSubjects {
                id
                subject {
                    id
                    name
                }
                tutorSubjectsLevel {
                    id
                    level {
                        name
                    }
                    rate
                }
            }
            tutorEducations {
                educationLevel {
                    name
                }
                degreeProgram {
                    name
                }
                fileLink
            }
            isVerified
            isEmailVerified
            payoneerVerified    
        }
    }
`;

export const getBookingsByBatchNumber = gql`
    query getBookingsByBatchNumber($batch_number: String!){
        getBookingsByBatchNumber(batch_number: $batch_number){
        id
        batch_number
        isGeneratedByAdmin
        rateOfLevel
        totalDuration
        subject{
            id
            name
        }
        level{
            id
            name
        }
        tutor{
            id
            firstName
            lastName
        }
        student{
            id
            firstName
            lastName
        }
        date
        startTime
        endTime
        orderAmount
        tutorAmount
        paymentType
        studyType
        reasonForCancellation
        orderNo
        status
        createdAt
        nextStatus
        paymentSessionId
        paymentSuccessIndicator
        }
    }
`;

export const updateBookingStatusAfterPaymentByBatchNumber = gql`
    mutation updateBookingStatusAfterPaymentByBatchNumber($batch_number: String!){
        updateBookingStatusAfterPaymentByBatchNumber(batch_number: $batch_number)
    }
`;

export const updateTutorSubject = gql`
    mutation updateTutorSubject(
        $tutorSubjectId: String!
        $subjectLevelRate: [InputLevelRate!]!
    ) {
        updateTutorSubject(
            tutorSubjectId: $tutorSubjectId
            subjectLevelRate: $subjectLevelRate
        ) {
            id
            subject {
                id
                name
            }
            tutorSubjectsLevel {
                id
                level {
                    id
                    name
                }
                rate
            }
        }
    }
`;

export const removeTutorSubject = gql`
    mutation removeTutorSubject($tutorSubjectId: String!) {
        removeTutorSubject(tutorSubjectId: $tutorSubjectId) {
            id
            subject {
                id
                name
            }
            tutorSubjectsLevel {
                id
                level {
                    name
                }
                rate
            }
        }
    }
`;

export const addPaymentDetails = gql`
    mutation addPaymentDetails(
        $paymentMethod: String!
        $payoneerEmail: String
        $inputBankDetails: InputTutorBankDetails
    ) {
        addPaymentDetails(
            paymentMethod: $paymentMethod
            payoneerEmail: $payoneerEmail
            inputBankDetails: $inputBankDetails
        ) {
            id
            payoneerEmail
            paymentMethod
            tutorBankDetails {
                id
                accountTitle
                accountNumber
                iban
                transitNumber
            }
        }
    }
`;

export const updatePaymentDetails = gql`
    mutation updatePaymentDetails(
        $paymentMethod: String
        $payoneerEmail: String
        $updateBankDetails: UpdateTutorBankDetails
    ) {
        updatePaymentDetails(
            paymentMethod: $paymentMethod
            payoneerEmail: $payoneerEmail
            updateBankDetails: $updateBankDetails
        ) {
            id
            payoneerEmail
            paymentMethod
            tutorBankDetails {
                id
                accountTitle
                accountNumber
                iban
                transitNumber
            }
        }
    }
`;

export const addTutorSubject = gql`
    mutation addTutorSubject($tutorSubject: InputTutorSubject!) {
        addTutorSubject(tutorSubject: $tutorSubject) {
            id
            subject {
                id
                name
            }
            tutorSubjectsLevel {
                id
                level {
                    name
                }
                rate
            }
        }
    }
`;

export const addTutorEducation = gql`
    mutation addTutorEducation($tutorEducation: InputTutorEducation!) {
        addTutorEducation(tutorEducation: $tutorEducation) {
            id
            educationLevel {
                id
                name
            }
            degreeProgram {
                id
                name
            }
            fileLink
        }
    }
`;

export const removeTutorEducation = gql`
    mutation removeTutorEducation($tutorEducationId: String!) {
        removeTutorEducation(tutorEducationId: $tutorEducationId) {
            id
            educationLevel {
                id
                name
            }
            degreeProgram {
                id
                name
            }
            fileLink
        }
    }
`;

export const updateMySchedule = gql`
    mutation updateMySchedule($updateMyScheduleVar: [UpdateScheduleInput!]!) {
        updateMySchedule(updateScheduleInput: $updateMyScheduleVar)
    }
`;

export const createBooking = gql`
    mutation createBooking($booking: CreateBookingInput!) {
        createBooking(createBookingInput: $booking) {
            id
            tutor {
                id
            }
            student {
                id
            }
            subject {
                id
            }
            level {
                id
            }
            totalDuration
            startTime
            endTime
            orderAmount
            tutorAmount
            paymentType
            orderNo
            createdAt
            paymentSessionId
        }
    }
`;

export const updateBookingStatusAfterPayment = gql`
    mutation updateBookingStatusAfterPayment($bookingId: String!) {
        updateBookingStatusAfterPayment(bookingId: $bookingId) 
    }
`;

export const createReview = gql`
    mutation createReview($review: CreateReviewInput!) {
        createReview(createReviewInput: $review) {
            id
            booking {
                id
                tutor {
                    id
                    firstName
                    lastName
                }
                student {
                    id
                    firstName
                    lastName
                }
            }
            rating
            description
            status
        }
    }
`;

export const createMultipleBooking = gql`
    mutation createMultipleBooking($tutorId: String!, $createBookingInput: [CreateMultipleBookingInputByAdmin!]!){
        createMultipleBooking(tutorId: $tutorId, createBookingInput: $createBookingInput){
            sessionId
            bookings{
                batch_number
                id
            }
        }
    }
`;

export const requestWithdraw = gql`
    mutation requestWithdraw($requestWithdraw: RequestWithdrawType!) {
        requestWithdraw(requestWithdraw: $requestWithdraw) {
            id
            status
            amount
            updatedAmount
            createdAt
        }
    }
`;


export const getReviews = gql`
query getTutorReviews($tutorId: String!) {
    getTutorReviews(tutorId: $tutorId) {
            id
            rating
            description
            title
            booking {
                id
                student {
                    id
                    firstName
                    lastName
                }
            }
        }
    }
`;

export const getTutorStars = gql`
query getUserStars($tutorId: String!) {
    getUserStars(tutorId: $tutorId) {
            fiveStar
            fourStar
            threeStar
            twoStar
            oneStar
            fiveStarPer
            fourStarPer
            threeStarPer
            twoStarPer
            oneStarPer
        }
    }
`;


export const updateTutorTimezone = gql`
    mutation updateTimeZone($timezone: UpdateTimzoneDTO!) {
        updateTimeZone(timezone: $timezone)
    }
`;
export const updateBookingStatus = gql`
    mutation updateBookingStatus($bookingId: String! $status: String!) {
        updateBookingStatus(bookingId: $bookingId, status: $status)
    }
`;

export const saveTransaction = gql`
    mutation saveTransaction($saveTransactionInput: SaveTransactionInput!){
        saveTransaction(saveTransactionInput: $saveTransactionInput)
    }
`;

export const resetPassword = gql`
    mutation resetPassword($resetPasswordInput: ResetPasswordInput!) {
        resetPassword(resetPasswordInput: $resetPasswordInput)
    }
`;

export const setPassword = gql`
    mutation setPassword($token: String!, $password: String!) {
        setPassword(token: $token, password: $password)
    }
`;


export const otpMobileNumber = gql`
    mutation testOtp($mobileNumber: String!) {
        testOtp(mobileNumber: $mobileNumber)
    }
`;

export const sendOtp = gql`
    mutation sendOtp {
        sendOtp 
    }
`;

export const sendOtpForOnboarding = gql`
    mutation sendOtpForOnboarding($contactNo: String!) {
        sendOtpForOnboarding(contactNo: $contactNo)
    }
`;

export const verifyOtp = gql`
    mutation verifyOtp($loginUserInput:  UserLoginInput!, $otp: Float!){
        verifyOtp(loginUserInput: $loginUserInput, otp: $otp) {
            user {
                id
                email
                firstName
                lastName
                userRole {
                    id
                    name
                }
                isVerified
            }
            access_token
        }
    }
`;

export const otpVerificationOnly = gql`
    mutation userOtpVerification($otp: Float!){
        userOtpVerification(otp: $otp) 
    }
`;

export const getMySchedule = gql`
    query getMySchedule {
        getMySchedule {
            id
            day
            isAvailable
            slot {
                id
                startTime
                endTime
            }
        }
    }
`;

export const getTutorSchedule = gql`
    query getTutorSchedule($tutorId: String!) {
        getTutorSchedule(tutorId: $tutorId) {
            id
            day
            isAvailable
            slot {
                id
                startTime
                endTime
            }
        }
    }
`;

export const forgotPassword = gql`
    mutation forgotPassword($email: String!){
        forgotPassword(email: $email)
}
`;

export const getMyBookingsStudent = gql`
query getMyBookingsStudent($status: String!,$date: Float!,$tutor: String!,$limit: Float!, $pageIndex: Float! ) {
    getMyBookingsStudent(status: $status, date: $date, tutor: $tutor, limit: $limit, pageIndex: $pageIndex) 
    {
        data {

                id
                tutor {
                    id
                    firstName
                    lastName
                }
                student {
                    id
                    firstName
                    lastName
                }
                subject {
                    id
                    name
                }
                level {
                    id
                    name
                }
                totalDuration
                startTime
                endTime
                orderAmount
                tutorAmount
                paymentType
                orderNo
                createdAt
                status
                nextStatus
                batch_number
                date
            }
        count 
    }    
           
    }
`;

export const getAvailableAmountInTutorWallet = gql`
    query getAvailableAmountInTutorWallet{
        getAvailableAmountInTutorWallet
    }
`;

export const getMyBookingsStudentNew = gql`
    query getMyBookingsStudentNew(
        $status: String
        $date: Float
        $tutor: String
        $subject: String
        $limit: Float!
        $pageIndex: Float!
    ) {
        getMyBookingsStudentNew(
            status: $status
            date: $date
            tutor: $tutor
            subject: $subject
            limit: $limit
            pageIndex: $pageIndex
        ) {
            data {
               bookings{
                id
                batch_number
                tutor {
                    id
                    firstName
                    lastName
                }
                student {
                    id
                    firstName
                    lastName
                }
                subject {
                    id
                    name
                }
                level {
                    id
                    name
                }
                totalDuration
                startTime
                endTime
                orderAmount
                tutorAmount
                paymentType
                reasonForCancellation
                orderNo
                createdAt
                status
               }
               batchNumber
            }
            count
        }
    }
`;

export const getMyBookingsTutor = gql`
query getMyBookingsTutor($status: String!,$date: Float!,$tutor: String!,$subject: String,$limit: Float!, $pageIndex: Float! ) {
    getMyBookingsTutor(status: $status, date: $date, tutor: $tutor, subject: $subject,limit: $limit, pageIndex: $pageIndex) 
        {
            data {
    
                    id
                    tutor {
                        id
                        firstName
                        lastName
                    }
                    student {
                        id
                        firstName
                        lastName
                    }
                    subject {
                        id
                        name
                    }
                    level {
                        id
                        name
                    }
                    totalDuration
                    startTime
                    endTime
                    orderAmount
                    tutorAmount
                    paymentType
                    orderNo
                    createdAt
                    status
                    nextStatus
                    review {
                        rating
                        description
                        title
                    }
                }
            count 
        }   
    }
`;

export const getTutorStats = gql`
query getTutorStats {
    getTutorStats  {
        totalSessions
        completedSessions
        totalEarnings
        totalHoursOfSessions
        totalAmountInWallet
    } 
}
`;

export const getTutorWallet = gql`
query getTutorWallet($limit: Float!, $pageIndex: Float! ) {
    getTutorWallet(limit: $limit, pageIndex: $pageIndex) {
        data {
            id
            amount
            status
            createdAt
            booking {
                id
                orderNo
                orderAmount
                tutorAmount
            }
            }
        count 
    }   
  }
`;

export const getStudentWallet = gql`
query getStudentWallet {
    getStudentWallet {
        id
        tutor
        amount
        status
        createdAt
        booking {
            id
        }
    }
 }
`;

export const getSlotsByDay = gql`
    query getSlotsByDay($tutorId: String!, $day: Float!) {
        getSlotsByDay(tutorId: $tutorId, timestampToExtractDay: $day)
    }
    `;

export const getTutorReviews = gql`
    query getTutorReviews($tutorId: String!) {
        getTutorReviews(tutorId: $tutorId) {
            id
            rating
            description
            booking {
                id
                student {
                    id
                    firstName
                    lastName
                }
                status
            }
        }
    }
    `;
export const connectToPayoneer = gql`
    query connectToPayoneer {
        connectToPayoneer {
            url
        }
    }
`;

export const addOrUpdateMessageReminder = gql`
    mutation addOrUpdateMessageReminder(
        $conversationId: String!
        $senderId: String!
        $receiverId: String!
    ) {
        addOrUpdateMessageReminder(
            conversationId: $conversationId
            senderId: $senderId
            receiverId: $receiverId
        )
    }
`;

export const resetUnreadMessageCount = gql`
    mutation resetUnreadMessageCount(
        $conversationId: String!,
        $receiverId: String!
    ) {
        resetUnreadMessageCount(
            conversationId: $conversationId
            receiverId: $receiverId
        )
    }
`;

export const getPayeeStatus = gql`
    query getPayeeStatus {
        getPayeeStatus{
            code
            status
        }
    }
`;


// momentDate
// momentUTCDate
// momentOffset
// timestampFormat
// timestampValueOf
// timestampOffsetFormat
// timestampOffset
// timestampUTCOffset
// dayOfWeek
// time12am
// time12amUTC
