<div [formGroup]="formGroup" class="flex flex-col sm:flex-row flex-auto mx-3 sm:mx-0 p-l-6 p-r-6 sm:p-4">
    <mat-form-field *ngIf="isTutor" class="flex-auto lg:px-1">
        <mat-select
            (selectionChange)="onSubjectSelection($event)"
            [formControlName]="'subject'"
            placeholder="Select Subject"
        >
            <mat-option *ngFor="let item of subjects" [value]="item">
                {{ item?.name | titlecase }}
            </mat-option>
        </mat-select>
        <mat-icon
            class="icon-size-5"
            matPrefix
            [svgIcon]="'heroicons_solid:academic-cap'"
        >
        </mat-icon>
    </mat-form-field>
    <mat-form-field class="flex-auto lg:px-1" *ngIf="showTutor">
        <mat-select
            (selectionChange)="onTutorSelection($event)"
            [formControlName]="'tutor'"
            placeholder="Select tutor"
        >
            <mat-option *ngFor="let item of tutors">
                {{ item?.name }}
            </mat-option>
        </mat-select>
        <mat-icon
            class="icon-size-5"
            matPrefix
            [svgIcon]="'heroicons_solid:academic-cap'"
        >
        </mat-icon>
    </mat-form-field>
    <mat-form-field appearance="fill" class="flex-auto lg:px-1" >
        <input placeholder="Please select a date (DD Mmm YYYY)"  (dateInput)="OnDateChange()" matInput [matDatepicker]="picker" [formControlName]="'date'" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

</div>
