import gql from 'graphql-tag';


export const signInUser = gql`mutation signInUser($loginUserInput:  UserLoginInput!){
    login(loginUserInput: $loginUserInput) {
        user {
            id
            email
            firstName
            lastName
            userRole {
                id
                name
            }
            isEmailVerified
            isVerified
        }
        access_token
    }
}`;

export const signupUser = gql`mutation signUpUser($userSignupInput: UserSignUpInput!){
    signUp(userSignupInput: $userSignupInput) {
		user {
            id
            email
            firstName
            userRole {
                id
                name
            }
            isEmailVerified
        }
        access_token
    }
}`;

export const getLoggedInUser = gql`query getLoggedInUser{
    getLoggedInUser{
        id
        firstName
        lastName
        description
        email
        paymentMethod
        payoneerEmail
        tutorBankDetails{
            id
            accountTitle
            accountNumber
            iban
            bankName
            branchName
            swiftCode
            ifscCode
            ukSortCode
            institutionNumber
            transitNumber
            accountType
            achRoutingNumber
        }
        contactNo
        gender
        avatar
        cnic_image_1
        cnic_image_2
        document_id
        label
        utc
        utcOffset
        city {
            id
            name
        }
        country {
            id
            name
        }
        language {
            id
            name
        }
        inPerson
        onlineCoaching
        userRole {
            id
            name
        }
        tutorSubjects {
            id
            subject {
                id
                name
            }
            tutorSubjectsLevel {
                id
                level {
                    name
                }
                rate
            }
        }
        tutorEducations {
            educationLevel {
                name
            }
            degreeProgram {
                name
            }
            fileLink
        }
        isVerified
        isEmailVerified
        payoneerVerified
    }
}`;


export const verifyEmail = gql `
    mutation verifyEmail($token: String!) {
        verifyEmail(token: $token)
    }
`;

export const sendEmailVerificationLink = gql `
    mutation sendEmailVerificationLink($email: String!) {
        sendEmailVerificationLink(email: $email)
    }
`;
