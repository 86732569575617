import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IsActiveMatchOptions } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FuseVerticalNavigationComponent } from '@fuse/components/navigation/vertical/vertical.component';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseNavigationItem } from '@fuse/components/navigation/navigation.types';
import { FuseUtilsService } from '@fuse/services/utils/utils.service';
import { CometChat } from '@cometchat-pro/chat';
import * as enums from "../../../../../../app/utils/enums";
import { logger } from "../../../../../../app/utils/common";
import { ChatService } from 'app/modules/admin/apps/chat/chat.service';

@Component({
    selector: 'fuse-vertical-navigation-basic-item',
    templateUrl: './basic.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FuseVerticalNavigationBasicItemComponent implements OnInit, OnDestroy {
    @Input() item: FuseNavigationItem;
    @Input() name: string;

    isActiveMatchOptions: IsActiveMatchOptions;
    private _fuseVerticalNavigationComponent: FuseVerticalNavigationComponent;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    unreadCount = 0
    listenerID = enums.MESSAGE_ + new Date().getTime();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseUtilsService: FuseUtilsService,
        private _chatService: ChatService
    ) {
        // Set the equivalent of {exact: false} as default for active match options.
        // We are not assigning the item.isActiveMatchOptions directly to the
        // [routerLinkActiveOptions] because if it's "undefined" initially, the router
        // will throw an error and stop working.
        this.isActiveMatchOptions = this._fuseUtilsService.subsetMatchOptions;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Set the "isActiveMatchOptions" either from item's
        // "isActiveMatchOptions" or the equivalent form of
        // item's "exactMatch" option
        this.isActiveMatchOptions =
            this.item.isActiveMatchOptions ?? this.item.exactMatch
                ? this._fuseUtilsService.exactMatchOptions
                : this._fuseUtilsService.subsetMatchOptions;

        // Get the parent navigation component
        this._fuseVerticalNavigationComponent = this._fuseNavigationService.getComponent(this.name);

        // Mark for check
        this._changeDetectorRef.markForCheck();

        // Subscribe to onRefreshed on the navigation component
        // this._fuseVerticalNavigationComponent.onRefreshed.pipe(
        //     takeUntil(this._unsubscribeAll)
        // ).subscribe(() => {

        //     // Mark for check
        //     this._changeDetectorRef.markForCheck();
        // });



        // get unread message count
        this.getUnreadMessageCount()
        
        // Text message listenere
        CometChat.addMessageListener(
            this.listenerID,
            new CometChat.MessageListener({
                onTextMessageReceived: textMessage => {
                    console.log("Text message received successfully", textMessage);
                    this.getUnreadMessageCount()
                }
            })
        );


        // update unread count when message is read
        this._chatService.chatSelected$.subscribe((value) => {
            console.log(value)
            this.unreadCount -= value
            this._changeDetectorRef.detectChanges()
        })


    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();

        try {
            this.removeListeners();
        } catch (error) {
            logger(error);
        }
    }


    getUnreadMessageCount() {
        // Get unread messages count
        CometChat.getUnreadMessageCountForAllUsers().then(
            (unreadMessageCount: Object) => {

                this.unreadCount = 0

                for (const key in unreadMessageCount) {
                    this.unreadCount += unreadMessageCount[key]
                }

                console.log(this.unreadCount)
                this._changeDetectorRef.detectChanges()

                console.log("Message count fetched", unreadMessageCount);
            }, (error: CometChat.CometChatException) => {
                console.log("Error in getting message count", error);
            }
        );
    }

    /**
    * Removes all listeners
    */
    removeListeners() {
        try {
            CometChat.removeMessageListener(this.listenerID);
        } catch (error) {
            logger(error);
        }
    }
}
