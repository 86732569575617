import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-privacy-policy',
    templateUrl: './privacy-policy.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ["./privacy-policy.component.scss"],
})
export class PrivacyPolicyComponent implements OnInit {
    src = './assets/files/Privacy Policy.pdf';

    constructor() { }

    ngOnInit(): void {
    }

}
