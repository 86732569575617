import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable, of, switchMap, tap, throwError } from 'rxjs';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { Apollo } from 'apollo-angular';
import { sendEmailVerificationLink, signInUser, signupUser, verifyEmail } from './auth.service.queries';
import { CometChat } from '@cometchat-pro/chat';
import { verifyOtp } from '../ontute/ontute.service.queries';

@Injectable()
export class AuthService {
    userLoginData;
    private _authenticated: boolean = false;
    private _onboardingPending: boolean = false
    // private _onboardingPending: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);


    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService,
        private apollo: Apollo,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }

    /**
     * Setter & getter for onboarding pending flag
     */
    set onboardingPending(value: boolean) {
        // Store the value
        this._onboardingPending = value
    }

    get onboardingPending$(): boolean {
        return this._onboardingPending;
        // return this._onboardingPending.asObservable();
    }



    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        return this._httpClient.post('api/auth/forgot-password', email);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any> {
        return this._httpClient.post('api/auth/reset-password', password);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(email: string, password: string): Observable<any> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError('User is already logged in.');
        }

        return this.apollo.mutate({
            mutation: signInUser,
            variables: {
                loginUserInput: {
                    email: email,
                    password: password,
                    isAdmin: false
                }
            },
            fetchPolicy: 'network-only',
        }).pipe(
            tap(
                (result) => {

                    if (result.data['login']) {

                        // Store the access token in the local storage
                        this.accessToken = result.data['login'].access_token;

                        // Set the authenticated flag to true
                        this._authenticated = true;

                        if (!result.data['login']['user'].firstName) {
                            this._onboardingPending = true
                        }

                        const {
                            qualification,
                            tutionType,
                            tutorSubjects,
                            education,
                            identityDocuments,
                            dob,
                            city,
                            country,
                            gender,
                            description,
                            language,
                            ...other
                        } = result.data['login']['user']
                        // Store the user on the user service
                        this._userService.user = { ...other, avatar: "", status: "", type: "" };
                        // this._userService.user$
                        // .subscribe((user: User) => {
                        //     console.log(user)
                        //     // Mark for check
                        //     // this._changeDetectorRef.markForCheck();
                        // });
                    }
                    // Return a new observable with the response
                    return of(result);
                }
            )
            // ,catchError( (error) => {
            //     console.log(error)
            //     return of(error)
            // })
        );
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any> {
        // Renew token
        return this._httpClient.post('api/auth/refresh-access-token', {
            accessToken: this.accessToken
        }).pipe(
            catchError(() =>

                // Return false
                of(false)
            ),
            switchMap((response: any) => {

                // Store the access token in the local storage
                this.accessToken = response.accessToken;

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = response.user;

                // Return true
                return of(true);
            })
        );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any> {
        // Remove the access token from the local storage
        localStorage.removeItem('accessToken');
        localStorage.removeItem('isVerified');

        // Set the authenticated flag to false
        this._authenticated = false;

        // Logout Cometchat
        CometChat.logout().then(
            (loggedOut: Object) => {
            }, (error: CometChat.CometChatException) => {
            }
        );

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    // signUp(user: { name: string; email: string; password: string; company: string }): Observable<any>
    // {
    //     return this._httpClient.post('api/auth/sign-up', user);
    // }

    signUp(email: string, password: string, role_id: number): Observable<any> {
        return this.apollo.mutate({
            mutation: signupUser,
            variables: {
                userSignupInput: {
                    email: email,
                    password: password,
                    role_id: role_id
                }
            }
        }).pipe(
            tap((result) => {
                // Store the access token in the local storage
                this.accessToken = result.data['signUp'].access_token;

                // Set the authenticated flag to true
                this._authenticated = true;

                // set the onboarding pending flag to true
                this.onboardingPending = true;
            })
        )
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {

        // Check the access token availability
        if (!this.accessToken) {
            return of(false);
        }

        // Check the access token is valid for the app
        // if (!AuthUtils._isTokenValid(this.accessToken)) {
        //     return of(false);
        // }

        // Check the access token expire date
        if (AuthUtils.isTokenExpired(this.accessToken)) {
            return of(false);
        }

        // Check if the user is logged in
        if (this._authenticated) {
            return of(true);
        }


        if (this.accessToken) {
            return of(true);
        }


        // If the access token exists and it didn't expire, sign in using it
        // return this.signInUsingToken();
    }

    checkEmailVerification(): Observable<boolean> {

        // Check the access token availability
        if (!this.accessToken) {
            return of(false);
        }

        // get user from the backend and verify onboarded status.
        return this._userService.get()
            .pipe(
                switchMap((user) => {
                    if (!user.data['getLoggedInUser'].isEmailVerified) {
                        return of(false)
                    }

                    return of(true)
                })
            )

    }

    /**
     * Check the onboarding status
     */
    checkOnboarding(): Observable<boolean> {

        if (this._onboardingPending) {
            return of(false)
        }

        // get user from the backend and verify onboarded status.
        return this._userService.get()
            .pipe(
                switchMap((user) => {
                    if (!user.data['getLoggedInUser'].firstName) {
                        this._onboardingPending = true
                        return of(false)
                    }

                    return of(true)
                })
            )

    }


    public verifyOtp(email: string, password: string, isAdmin: boolean, otpValue) {
        return this.apollo.mutate({
            mutation: verifyOtp,
            variables: {
                loginUserInput: {
                    email: email,
                    password: password,
                    isAdmin: isAdmin
                },
                otp: otpValue
            },
        })
    }


    public verifyEmail(token) {
        return this.apollo.mutate({
            mutation: verifyEmail,
            variables: {
                token: token
            },
        });
    }

    public sendEmailVerificationLink(email) {
        return this.apollo.mutate({
            mutation: sendEmailVerificationLink,
            variables: {
                email: email
            },
        });
    }
}
